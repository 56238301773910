<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Latest Version')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >

    <b-row class="mt-1">
      <b-form-input
        id="input-lg-buttonColor"
        v-model="latestVersion"
        :placeholder="$t('Latest Version')"
      />
    </b-row>
    <template #modal-footer>
      <div class="w-100">

        <div
          class="float-right"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="versionChange"
          >
            {{ $t('Accept') }}
            <feather-icon
              icon="ShoppingBagIcon"
              size="12"
            />
          </b-button>

        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      latestVersion: '', // สถานะการทำ api สำหรับ web Scraping
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, latestVersion,
          } = this.data
          this.dataId = _id
          this.latestVersion = latestVersion
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.latestVersion = ''
    },
    versionChange(name, status) {
      
      const obj = {
        id: this.dataId,
        latestVersion: this.latestVersion,
      }
      store
        .dispatch(`${this.storeModuleName}/versionChange`, obj)
        .then(result => {
          
          this.$emit('update:is-modal-active', false)
          this.$emit('update:show', false)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
