<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="logo"
                        ref="previewEl"
                        rounded
                        :src="logo"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Name')"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Detail -->
              <b-form-group
                :label="$t('Detail')"
                label-for="register-detail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  vid="detail"
                  rules="required"
                >
                  <b-form-input
                    id="register-detail"
                    v-model="detail"
                    name="register-detail"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- urlLogin -->
              <b-form-group
                :label="$t('UrlLogin')"
                label-for="register-urlLogin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="UrlLogin"
                  vid="urlLogin"
                  rules=""
                >
                  <b-form-input
                    id="register-urlLogin"
                    v-model="urlLogin"
                    name="register-urlLogin"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('UrlLogin')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- urlLogin -->
              <b-form-group
                :label="$t('iOS')"
                label-for="register-iOS"
              >
                <validation-provider
                  #default="{ errors }"
                  name="iOS"
                  vid="iOS"
                  rules=""
                >
                  <b-form-input
                    id="register-iOS"
                    v-model="ios"
                    name="register-iOS"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('iOS')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- urlLogin -->
              <b-form-group
                :label="$t('Android')"
                label-for="register-Android"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Android"
                  vid="Android"
                  rules=""
                >
                  <b-form-input
                    id="register-Android"
                    v-model="android"
                    name="register-Android"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Android')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="dataId === null"
              cols="12"
            >
              <b-form-group
                :label="$t('CreatePackageApplication')"
                label-for="register-createPackageApplication"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CreatePackageApplication"
                  vid="createPackageApplication"
                  rules="required"
                >
                  <v-select
                    id="register-createPackageApplication"
                    v-model="createPackageApplicationId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="respDataCreatePackageApplication"
                    :reduce="(respDataCreatePackageApplication) => respDataCreatePackageApplication._id"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col
              v-if="dataId === null"
              cols="12"
            >
              <b-form-group
                :label="$t('Company')"
                label-for="register-company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company"
                  vid="company"
                  rules="required"
                >
                  <v-select
                    id="register-company"
                    v-model="companyId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="respDataCompany"
                    :reduce="(respDataCompany) => respDataCompany._id"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput, BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      logo: '',
      name: '',
      detail: '',
      urlLogin: '',
      ios: '',
      android: '',
      createPackageApplicationId: '',
      companyId: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    respDataCreatePackageApplication() {
      return store.state[this.storeModuleName].respDataCreatePackageApplication != null ? store.state[this.storeModuleName].respDataCreatePackageApplication.data : []
    },
    respDataCompany() {
      return store.state[this.storeModuleName].respDataCompany != null ? store.state[this.storeModuleName].respDataCompany.data : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, logo, name, detail, urlLogin, urlApp, createPackageApplicationId, companyId,
          } = this.data
          this.dataId = _id
          this.logo = logo
          this.name = name
          this.detail = detail
          this.urlLogin = urlLogin
          if (urlApp !== null) {
            this.ios = urlApp.ios
            this.android = urlApp.android
          } else {
            this.ios = ''
            this.android = ''
          }
          this.createPackageApplicationId = createPackageApplicationId._id
          this.companyId = companyId._id
        }
      }
    },
  },
  methods: {
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.logo = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.logo = ''
    },
    initValues() {
      this.dataId = null
      this.logo = ''
      this.name = ''
      this.detail = ''
      this.urlLogin = ''
      this.ios = ''
      this.android = ''
      this.createPackageApplicationId = ''
      this.companyId = ''
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            logo: this.logo,
            name: this.name,
            detail: this.detail,
            urlLogin: this.urlLogin,
            urlApp: { ios: this.ios, android: this.android },
            createPackageApplicationId: this.createPackageApplicationId,
            companyId: this.companyId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            delete obj.createPackageApplicationId
            delete obj.companyId
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
